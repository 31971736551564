export const API_URL = window.globalConfigVariables && window.globalConfigVariables.apiUrl ? window.globalConfigVariables.apiUrl : 'https://devapi24hassistancecore.24hassistance.com/api';
export const WEBORDER_API = window.globalConfigVariables && window.globalConfigVariables.webOrderApiUrl ? window.globalConfigVariables.webOrderApiUrl : 'https://dev-b2c-dataservice-gateway.24hassistance.com/api';

export const SCHEDULING_API_URL = window.globalConfigVariables && window.globalConfigVariables.paperApiUrl ?
    window.globalConfigVariables.paperApiUrl : 'https://devapibo24hassistancecore.24hassistance.com/api';

export const SCHEDULING_API_KEY = window.globalConfigVariables && window.globalConfigVariables.paperApiKey ?
    window.globalConfigVariables.paperApiKey : '1559ED35-2FB1-47CF-915C-526D9DA2DFC5';



export const GOOGLE_MAPS_APIKEY = 'AIzaSyCU3qVMv14RFL8owX5QkjamhOSjv72Ojpg';
export const GATEWAY_CREDENTIALS = window.globalConfigVariables && window.globalConfigVariables.webOrderApiHeader ? window.globalConfigVariables.webOrderApiHeader : 'RGlnaXRhbF9WdWVBcHA6MUZGMTdEMkMtMjRFNS00MjM3LUI0RTctMUE4QkI4MTEwOEEz'
// export const FACILE_IT_KEY = window.location.host === 'www.24hassistance.com' ? '6lTR4boUWAb-rHZwSfQyDIkV94dpjmVnp3cv9r-Diqw':'zbb59Jl_BnvNpjDIRxkvmaPxymGRDTaks1Ru3sWfZws';
export const FACILE_IT_KEY = '6lTR4boUWAb-rHZwSfQyDIkV94dpjmVnp3cv9r-Diqw';

export const CALENDAR_CONFIG = {
    start: {
        timeAdjust: '00:00:00',
    },
    end: {
        timeAdjust: '00:00:00',
    },
};
export const CALENDAR_COLORS_CONFIG = {
    highlight: {
        start: {
            style: {
                backgroundColor: '#e4f1fc', // blue
            },
            contentStyle: {
                color: 'black' // color of the text
            },
            fillMode: "outline"
        },
        base: {
            style: {
                backgroundColor: '#e4f1fc',
            },
            contentStyle: {
                color: '#black', // color of the text
            }
        },
        end: {
            style: {
                backgroundColor: '#e4f1fc', // blue
            },
            contentStyle: {
                color: 'black' // color of the text
            },
            fillMode: "outline",

        }
    }
};
export const PARTNER_LIST = [
    {name: "Kawasaki", product: "MP", imageName: "moto_kawasaki_color.png"},
    {name: "Kymco", product: "MP", imageName: "moto_kymco_color.png"},
    {name: "Suzuki", product: "MP", imageName: "moto_suzuki_color.png"},
    {name: "Sym", product: "MP", imageName: "moto_sym_color.png"},
    {name: "Benelli", product: "MP", imageName: "moto_benelli_color.png"},
    { name: "Triumph", product: "MP", imageName: "moto_triumph_color.png" },
    { name: "TVS", product: "MP", imageName: "moto_tvs_color.png" },
    {name: "Peugeot Motorcycles", product: "MP", imageName: "moto_peugeot_color.png"},
    {name: "Fantic Motor", product: "MP", imageName: "moto_fantic_color.png"},
    {name: "Moto Motomorini", product: "MP", imageName: "moto_motomorini_color.png"},
    {name: "Voge Italia", product: "MP", imageName: "moto_voge_color.png"},
    {name: "Royal Enfield", product: "MP", imageName: "moto_royal_enfield_color.png"},
    {name: "CF Moto", product: "MP", imageName: "moto_cfmoto_color.png"},
    {name: "Lifan", product: "MP", imageName: "moto_lifan_color.png"},
    {name: "Lambretta", product: "MP", imageName: "moto_lambretta_color.png"},
    {name: "Malaguti", product: "MP", imageName: "moto_malaguti_color.png"},
    {name: "Askoll", product: "MP", imageName: "moto_askoll_color.png"},
    {name: "Beta", product: "MP", imageName: "moto_betamotor_color.png"},
    {name: "Brixton Motorcycles", product: "MP", imageName: "moto_brixton_color.png"},
    {name: "Desartica", product: "MP", imageName: "moto_desartica_color.png"},
    {name: "Cosaporto", product: "MP", imageName: "cosaporto-color.png"},
    {name: "Fb Mondial", product: "MP", imageName: "moto_mondial_color.png"},
    {name: "ICON.E", product: "MP", imageName: "moto_icon_e_color.png"},
    {name: "Keeway Motor", product: "MP", imageName: "moto_keeway_color.png"},
    {name: "KSR Moto", product: "MP", imageName: "moto_ksrmoto_color.png"},
    {name: "Mash", product: "MP", imageName: "moto_mash_color.png"},
    { name: "Missbiker", product: "MP", imageName: "moto_missbiker_color.png" },
    { name: "Morbidelli", product: "MP", imageName: "moto_morbidelli_color.png" },
    {name: "MOTO.APP", product: "MP", imageName: "moto_motoapp_color.png"},
    {name: "Motorstock", product: "MP", imageName: "moto_motorstock_color.png"},
    {name: "Motron", product: "MP", imageName: "moto_motron_color.png"},
    {name: "Norton", product: "MP", imageName: "moto_norton_color.png"},
    {name: "NIU", product: "MP", imageName: "moto_niu_color.png"},
    {name: "QJ Motor", product: "MP", imageName: "qjmotor_color.png"},
    
    {name: "Studio CMTLegal", product: "MP", imageName: "moto_studio_CMT_color.png"},
    {name: "Super Soco", product: "MP", imageName: "moto_supersoco_color.png"},
    {name: "Swm", product: "MP", imageName: "moto_swm_color.png"},
    {name: "Wottan Motor", product: "MP", imageName: "moto_wottan_color.png"},
    {name: "Yadea.png", product: "MP", imageName: "moto_yadea_color.png"},
    {name: "Zero Motorcycles", product: "MP", imageName: "moto_zero_color.png"},
    {name: "Zontes", product: "MP", imageName: "moto_zontes_color.png"},
    //DD
    {name: "ANMVI", product: "DD", imageName: "cane_anmvi_color.png"},
    {name: "Isola dei Tesori", product: "DD", imageName: "cane_isoladeitesori_color.png"},
    {name: "Il gigante", product: "DD", imageName: "cane_ilgigante_color.png"},
    {name: "Balzoo", product: "DD", imageName: "cane_balzoo_color.png"},
    {name: "Just Dog", product: "DD", imageName: "cane_justdog_color.png"},
    {name: "Bauzaar", product: "DD", imageName: "cane_bauzaar_color.png"},
    // SK
    {name: "Maxitender", product: "SK", imageName: "barca_Maxitender_color.png"},
    {name: "Sacs Marine", product: "SK", imageName: "barca_sacs_color.png"},

    // BP
    {name: "Scott", product: "BP", imageName: "bici_scott_color.png"},
    {name: "Bergamont", product: "BP", imageName: "bici_bergamont_color.png"},
    {name: "Cicli Olympia", product: "BP", imageName: "bici_cicli_olympia_color.png"},
    {name: "Ekletta", product: "BP", imageName: "bici_ekletta_color.png"},
    {name: "Fantic Motor", product: "BP", imageName: "moto_fantic_color.png"},
    {name: "MBM Cicli", product: "BP", imageName: "bici_mbm_color.png"},
    {name: "Torpado", product: "BP", imageName: "bici_torpado_color.png"},
    {name: "V-ITA", product: "BP", imageName: "bici_v_ita_color.png"},
    {name: "World Dimension", product: "BP", imageName: "bici_world_dimension_color.png"},
    //MS
    {name: "Dovesciare", product: "MS", imageName: "sci_dovesciare_color.png"},
    {name: "6punto9", product: "MS", imageName: "sport_6punto9_color.png"},
    {name: "Asd emissionizero", product: "MS", imageName: "sport_asdemissionizero_color.png"},
    {name: "Neveitalia", product: "MS", imageName: "sport_neveitalia_color.png"},
    {name: "Dolomiti Superski", product: "MS", imageName: "sci_dolomiti__color.png"},
    {name: "EMT Borno", product: "MS", imageName: "sport_emtborno_color.png"},
    {name: "Adamello ski", product: "MS", imageName: "sci_adamelloski.png"},
    {name: "Bormio", product: "MS", imageName: "Bormio-Ski-logo_color.png"},
    {name: "Barzio", product: "MS", imageName: "bobbio-2trasp_orig_color.png"},
    {name: "cervinia", product: "MS", imageName: "sci_cervinia_color.png"},
    {name: "courmayeur", product: "MS", imageName: "sci_courmayeur_color.png"},
    {name: "lathuile", product: "MS", imageName: "sci_lathuile_color.png"},
    {name: "monterosaski", product: "MS", imageName: "MonterosaSki_color.png"},
    {name: "pila", product: "MS", imageName: "sci_pila.png"},
    {name: "sciare", product: "MS", imageName: "sci_sciaremag_color.png"},
    {name: "3-Zinnen logo", product: "MS", imageName: "3-Zinnen_color.png"},
    {name: "Alagna logo", product: "MS", imageName: "alagna_6902212_color.jpg"},
    {name: "AlpeDevero logo", product: "MS", imageName: "AlpeDevero_color.png"},
    {name: "Aprica logo", product: "MS", imageName: "Aprica_color.png"},
    {name: "Arabba logo", product: "MS", imageName: "arabba_color.png"},
    {name: "Bielmonte logo", product: "MS", imageName: "bielmonte2_color.png"},
    {name: "Cervino", product: "MS", imageName: "Cervino_color.png"},
    {name: "Cimone", product: "MS", imageName: "Cimone_color.jpg"},
    {name: "Civetta", product: "MS", imageName: "Civetta_color.jpg"},
    {name: "Corno alle Scale", product: "MS", imageName: "Corno-alle-scale_color.png"},
    {name: "Domobianca", product: "MS", imageName: "Domobianca_2012-logo-x-sito_color.gif"},
    {name: "EnjoySki", product: "MS", imageName: "EnjoySki_color.png"},
    {name: "Estiul SkiSchool", product: "MS", imageName: "Estiul_SkiSchool_color.png"},
    {name: "Klausberg", product: "MS", imageName: "Klausberg_color.png"},
    {name: "Lathuile", product: "MS", imageName: "Lathuile_color.jpeg"},
    {name: "Macugnaga", product: "MS", imageName: "Macugnaga_color.jpg"},
    {name: "Mera ski", product: "MS", imageName: "Mera-ski_color.jpg"},
    {name: "Mondole Ski", product: "MS", imageName: "Mondole-Ski-logo_color.jpg"},
    {name: "Montepora", product: "MS", imageName: "Monte-pora_color.png"},
    {name: "Piamprato", product: "MS", imageName: "Piamprato_color.png"},
    {name: "Pian di Vigezzo", product: "MS", imageName: "Piana-di-vigezzo_color.png"},
    {name: "Pian neiretto", product: "MS", imageName: "Pian-neiretto_color.png"},
    {name: "Plan-de-Corones", product: "MS", imageName: "Plan-de-Corones_color.jpg"},
    {name: "Prali", product: "MS", imageName: "Prali_color.png"},
    {name: "Presolana Montepora", product: "MS", imageName: "logo_Montepora_color.png"},
    {name: "Promoturismo", product: "MS", imageName: "promoturismo_color.png"},
    {name: "PKR Kitesurf", product: "MS", imageName: "sport_pkr_kitesurf.png"},
    {name: "San Domenico di Varzo", product: "MS", imageName: "San-domenico-di-varzo_color.png"},
    {name: "San Giacomo Roburent", product: "MS", imageName: "SanGiacomoRoburent_color.png"},
    {name: "Santa Caterina", product: "MS", imageName: "Santa-caterina_color.jpg"},
    {name: "Scuola Nazionale Sci Sestriere", product: "MS", imageName: "ScuolaNazionaleSciSestriere_color.png"},
    {name: "SkiEmotion", product: "MS", imageName: "SkiEmotion_color.png"},
    {name: "Snowit", product: "MS", imageName: "logo_snowit_color.svg"},
    {name: "Snowmet", product: "MS", imageName: "snowmet_color.jpg"},
    {name: "Speikboden", product: "MS", imageName: "Speikboden_color.png"},
    {name: "Spiazzi di Gromo", product: "MS", imageName: "SpiazzidiGromo_color.png"},
    {name: "Stelvio", product: "MS", imageName: "Stelvio_color.jpg"},
    {name: "Trevalli", product: "MS", imageName: "Trevalli_color.png"},
    {name: "Tribala", product: "MS", imageName: "logo_tribala_color.svg"},
    {name: "Usseglio", product: "MS", imageName: "Usseglio_color.png"},
    {name: "Valchiavenna Madesimo", product: "MS", imageName: "ValchiavennaMadesimo_color.png"},
    {name: "Valgerola", product: "MS", imageName: "Valgerola_color.jpg"},
    {name: "Valmalenco", product: "MS", imageName: "Valmalenco_color.png"},

    // Multisport pagina partner: stessi loghi di SC meno quelli presenti in alto
    {name: "Adamello ski", product: "MSL", imageName: "sci_adamelloski.png"},
    {name: "Bormio", product: "MSL", imageName: "Bormio-Ski-logo_color.png"},
    {name: "Barzio", product: "MSL", imageName: "bobbio-2trasp_orig_color.png"},
    {name: "cervinia", product: "MSL", imageName: "sci_cervinia_color.png"},
    {name: "courmayeur", product: "MSL", imageName: "sci_courmayeur_color.png"},
    {name: "lathuile", product: "MSL", imageName: "sci_lathuile_color.png"},
    {name: "monterosaski", product: "MSL", imageName: "MonterosaSki_color.png"},
    {name: "pila", product: "MSL", imageName: "sci_pila.png"},
    {name: "sciare", product: "MSL", imageName: "sci_sciaremag_color.png"},
    {name: "3-Zinnen logo", product: "MSL", imageName: "3-Zinnen_color.png"},
    {name: "Alagna logo", product: "MSL", imageName: "alagna_6902212_color.jpg"},
    {name: "AlpeDevero logo", product: "MSL", imageName: "AlpeDevero_color.png"},
    {name: "Aprica logo", product: "MSL", imageName: "Aprica_color.png"},
    {name: "Arabba logo", product: "MSL", imageName: "arabba_color.png"},
    {name: "Bielmonte logo", product: "MSL", imageName: "bielmonte2_color.png"},
    {name: "Cervino", product: "MSL", imageName: "Cervino_color.png"},
    {name: "Cimone", product: "MSL", imageName: "Cimone_color.jpg"},
    {name: "Civetta", product: "MSL", imageName: "Civetta_color.jpg"},
    {name: "Corno alle Scale", product: "MSL", imageName: "Corno-alle-scale_color.png"},
    {name: "Dolomiti Superski", product: "MSL", imageName: "sci_dolomiti__color.png"},
    {name: "Domobianca", product: "MSL", imageName: "Domobianca_2012-logo-x-sito_color.gif"},
    {name: "EnjoySki", product: "MSL", imageName: "EnjoySki_color.png"},
    {name: "Estiul SkiSchool", product: "MSL", imageName: "Estiul_SkiSchool_color.png"},
    {name: "Klausberg", product: "MSL", imageName: "Klausberg_color.png"},
    {name: "Lathuile", product: "MSL", imageName: "Lathuile_color.jpeg"},
    {name: "Macugnaga", product: "MSL", imageName: "Macugnaga_color.jpg"},
    {name: "Mera ski", product: "MSL", imageName: "Mera-ski_color.jpg"},
    {name: "Mondole Ski", product: "MSL", imageName: "Mondole-Ski-logo_color.jpg"},
    {name: "Montepora", product: "MSL", imageName: "Monte-pora_color.png"},
    {name: "Piamprato", product: "MSL", imageName: "Piamprato_color.png"},
    {name: "Pian di Vigezzo", product: "MSL", imageName: "Piana-di-vigezzo_color.png"},
    {name: "Pian neiretto", product: "MSL", imageName: "Pian-neiretto_color.png"},
    {name: "Plan-de-Corones", product: "MSL", imageName: "Plan-de-Corones_color.jpg"},
    {name: "Prali", product: "MSL", imageName: "Prali_color.png"},
    {name: "Presolana Montepora", product: "MSL", imageName: "logo_Montepora_color.png"},
    {name: "Promoturismo", product: "MSL", imageName: "promoturismo_color.png"},
    {name: "San Domenico di Varzo", product: "MSL", imageName: "San-domenico-di-varzo_color.png"},
    {name: "San Giacomo Roburent", product: "MSL", imageName: "SanGiacomoRoburent_color.png"},
    {name: "Santa Caterina", product: "MSL", imageName: "Santa-caterina_color.jpg"},
    // {name: "Sci Dovesciare Big", product: "MSL", imageName: "sci_dovesciare_big.png"},
    {name: "Scuola Nazionale Sci Sestriere", product: "MSL", imageName: "ScuolaNazionaleSciSestriere_color.png"},
    {name: "SkiEmotion", product: "MSL", imageName: "SkiEmotion_color.png"},
    {name: "Snowit", product: "MSL", imageName: "logo_snowit_color.svg"},
    {name: "Snowmet", product: "MSL", imageName: "snowmet_color.jpg"},
    {name: "Speikboden", product: "MSL", imageName: "Speikboden_color.png"},
    {name: "Spiazzi di Gromo", product: "MSL", imageName: "SpiazzidiGromo_color.png"},
    {name: "Stelvio", product: "MSL", imageName: "Stelvio_color.jpg"},
    {name: "Trevalli", product: "MSL", imageName: "Trevalli_color.png"},
    {name: "Tribala", product: "MSL", imageName: "logo_tribala_color.svg"},
    {name: "Usseglio", product: "MSL", imageName: "Usseglio_color.png"},
    {name: "Valchiavenna Madesimo", product: "MSL", imageName: "ValchiavennaMadesimo_color.png"},
    {name: "Valgerola", product: "MSL", imageName: "Valgerola_color.jpg"},
    {name: "Valmalenco", product: "MSL", imageName: "Valmalenco_color.png"},


    //SC
    {name: "Adamello ski", product: "SC", imageName: "sci_adamelloski.png"},
    {name: "Bormio", product: "SC", imageName: "Bormio-Ski-logo_color.png"},
    {name: "Barzio", product: "SC", imageName: "bobbio-2trasp_orig_color.png"},
    {name: "cervinia", product: "SC", imageName: "sci_cervinia_color.png"},
    {name: "courmayeur", product: "SC", imageName: "sci_courmayeur_color.png"},
    {name: "lathuile", product: "SC", imageName: "sci_lathuile_color.png"},
    {name: "monterosaski", product: "SC", imageName: "MonterosaSki_color.png"},
    {name: "pila", product: "SC", imageName: "sci_pila.png"},
    {name: "sciare", product: "SC", imageName: "sci_sciaremag_color.png"},

    {name: "3-Zinnen logo", product: "SC", imageName: "3-Zinnen_color.png"},

    {name: "Alagna logo", product: "SC", imageName: "alagna_6902212_color.jpg"},
    {name: "AlpeDevero logo", product: "SC", imageName: "AlpeDevero_color.png"},
    {name: "Aprica logo", product: "SC", imageName: "Aprica_color.png"},
    {name: "Arabba logo", product: "SC", imageName: "arabba_color.png"},

    {name: "Bielmonte logo", product: "SC", imageName: "bielmonte2_color.png"},
    {
        name: "Borno ski",
        product: "SC",
        imageName: "borno_ski_area_vallecamonica_monte_altissimo_estate_inverno_darfo_boario_terme_logo_color.png"
    },
    {name: "Cervino", product: "SC", imageName: "Cervino_color.png"},
    {name: "Cimone", product: "SC", imageName: "Cimone_color.jpg"},
    {name: "Civetta", product: "SC", imageName: "Civetta_color.jpg"},
    {name: "Corno alle Scale", product: "SC", imageName: "Corno-alle-scale_color.png"},
    {name: "Domobianca", product: "SC", imageName: "Domobianca_2012-logo-x-sito_color.gif"},
    {name: "EnjoySki", product: "SC", imageName: "EnjoySki_color.png"},
    {name: "Estiul SkiSchool", product: "SC", imageName: "Estiul_SkiSchool_color.png"},
    {name: "Klausberg", product: "SC", imageName: "Klausberg_color.png"},
    {name: "Lathuile", product: "SC", imageName: "Lathuile_color.jpeg"},
    {name: "Macugnaga", product: "SC", imageName: "Macugnaga_color.jpg"},
    {name: "Mera ski", product: "SC", imageName: "Mera-ski_color.jpg"},
    {name: "Mondole Ski", product: "SC", imageName: "Mondole-Ski-logo_color.jpg"},
    {name: "Montepora", product: "SC", imageName: "Monte-pora_color.png"},
    {name: "Piamprato", product: "SC", imageName: "Piamprato_color.png"},
    {name: "Pian di Vigezzo", product: "SC", imageName: "Piana-di-vigezzo_color.png"},
    {name: "Pian neiretto", product: "SC", imageName: "Pian-neiretto_color.png"},
    {name: "Plan-de-Corones", product: "SC", imageName: "Plan-de-Corones_color.jpg"},
    {name: "Prali", product: "SC", imageName: "Prali_color.png"},
    {name: "Presolana Montepora", product: "SC", imageName: "logo_Montepora_color.png"},
    {name: "Promoturismo", product: "SC", imageName: "promoturismo_color.png"},
    {name: "San Domenico di Varzo", product: "SC", imageName: "San-domenico-di-varzo_color.png"},
    {name: "San Giacomo Roburent", product: "SC", imageName: "SanGiacomoRoburent_color.png"},
    {name: "Santa Caterina", product: "SC", imageName: "Santa-caterina_color.jpg"},
    {name: "Dolomiti Superski", product: "SC", imageName: "sci_dolomiti__color.png"},
    {name: "Sci Dovesciare Big", product: "SC", imageName: "sci_dovesciare_big.png"},
    {name: "Scuola Nazionale Sci Sestriere", product: "SC", imageName: "ScuolaNazionaleSciSestriere_color.png"},
    {name: "SkiEmotion", product: "SC", imageName: "SkiEmotion_color.png"},
    {name: "Snowit", product: "SC", imageName: "logo_snowit_color.svg"},
    {name: "Snowmet", product: "SC", imageName: "snowmet_color.jpg"},
    {name: "Snow week", product: "SC", imageName: "logo_snow_week.png"},
    {name: "Speikboden", product: "SC", imageName: "Speikboden_color.png"},
    {name: "Spiazzi di Gromo", product: "SC", imageName: "SpiazzidiGromo_color.png"},
    {name: "Stelvio", product: "SC", imageName: "Stelvio_color.jpg"},
    {name: "Trevalli", product: "SC", imageName: "Trevalli_color.png"},
    {name: "Usseglio", product: "SC", imageName: "Usseglio_color.png"},
    {name: "Valchiavenna Madesimo", product: "SC", imageName: "ValchiavennaMadesimo_color.png"},

    {name: "Valgerola", product: "SC", imageName: "Valgerola_color.jpg"},
    {name: "Valmalenco", product: "SC", imageName: "Valmalenco_color.png"},
];
export const PARTNER_LIST_GRAY = [
    {name: "Kawasaki", product: "MP", imageName: "moto_kawasaki.png"},
    {name: "Kymco", product: "MP", imageName: "moto_kymco.png"},
    {name: "Suzuki", product: "MP", imageName: "moto_suzuki.png"},
    {name: "Sym", product: "MP", imageName: "moto_sym.png"},
    {name: "Benelli", product: "MP", imageName: "moto_benelli.png"},
    { name: "Triumph", product: "MP", imageName: "moto_triumph.png" },
    { name: "TVS", product: "MP", imageName: "moto_tvs.png" },
    {name: "Peugeot Motorcycles", product: "MP", imageName: "moto_peugeot.png"},
    {name: "Fantic Motor", product: "MP", imageName: "moto_fantic.png"},
    {name: "Moto Motomorini", product: "MP", imageName: "moto_motomorini.png"},
    {name: "Voge Italia", product: "MP", imageName: "logo_voge.png"},
    {name: "Royal Enfield", product: "MP", imageName: "moto_royal_enfield.png"},
    {name: "CF Moto", product: "MP", imageName: "moto_cfmoto.png"},
    {name: "Lifan", product: "MP", imageName: "moto_lifan.png"},
    {name: "Lambretta", product: "MP", imageName: "moto_lambretta.png"},
    {name: "Malaguti", product: "MP", imageName: "moto_malaguti.png"},
    {name: "Askoll", product: "MP", imageName: "moto_askoll.png"},
    {name: "Beta", product: "MP", imageName: "moto_betamotor.png"},
    {name: "Brixton Motorcycles", product: "MP", imageName: "moto_brixton.png"},
    {name: "Desartica", product: "MP", imageName: "moto_desartica.png"},
    {name: "Cosaporto", product: "MP", imageName: "cosaporto.png"},
    {name: "Fb Mondial", product: "MP", imageName: "moto_mondial.png"},
    {name: "ICON.E", product: "MP", imageName: "moto_icon_e.png"},
    {name: "Keeway Motor", product: "MP", imageName: "moto_keeway.png"},
    {name: "KSR Moto", product: "MP", imageName: "moto_ksrmoto.png"},
    {name: "Mash", product: "MP", imageName: "moto_mash.png"},
    { name: "Missbiker", product: "MP", imageName: "moto_missbiker.png" },
    { name: "Morbidelli", product: "MP", imageName: "moto_morbidelli.png" },
    {name: "MOTO.APP", product: "MP", imageName: "moto_motoapp.png"},
    {name: "Motorstock", product: "MP", imageName: "moto_motorstock.png"},
    {name: "Motron", product: "MP", imageName: "moto_motron.png"},
    {name: "Norton", product: "MP", imageName: "moto_norton.png"},
    {name: "NIU", product: "MP", imageName: "moto_niu.png"},
    {name: "QJ Motor", product: "MP", imageName: "qjmotor.png"},
    {name: "Studio CMTLegal", product: "MP", imageName: "moto_studio_CMT.png"},
    {name: "Super Soco", product: "MP", imageName: "moto_supersoco.png"},
    {name: "Swm", product: "MP", imageName: "moto_swm.png"},
    {name: "Wottan Motor", product: "MP", imageName: "moto_wottan.png"},
    {name: "Yadea.png", product: "MP", imageName: "moto_yadea.png"},
    {name: "Zero Motorcycles", product: "MP", imageName: "moto_zero.png"},
    {name: "Zontes", product: "MP", imageName: "moto_zontes.png"},
    //DD
    {name: "ANMVI", product: "DD", imageName: "cane_anmvi.png"},
    {name: "Isola dei Tesori", product: "DD", imageName: "cane_isoladeitesori.png"},
    {name: "Il gigante", product: "DD", imageName: "cane_ilgigante.png"},
    {name: "Just Dog", product: "DD", imageName: "cane_just_dog.png"},
    {name: "Balzoo", product: "DD", imageName: "cane_balzoo.png"},
    {name: "Bauzaar", product: "DD", imageName: "cane_bauzaar.png"},
    // BP
    {name: "Scott", product: "BP", imageName: "bici_scott.png"},
    {name: "Bergamont", product: "BP", imageName: "bici_bergamont.png"},
    {name: "Cicli Olympia", product: "BP", imageName: "bici_cicli_olympia.png"},
    {name: "Ekletta", product: "BP", imageName: "bici_ekletta.png"},
    {name: "MBM Cicli", product: "BP", imageName: "bici_mbm.png"},
    {name: "Fantic Motor", product: "BP", imageName: "moto_fantic.png"},
    {name: "Torpado", product: "BP", imageName: "bici_torpado.png"},
    {name: "V-ITA", product: "BP", imageName: "bici_v_ita.png"},
    {name: "World Dimension", product: "BP", imageName: "bici_world_dimension.png"},
    //MS
    {name: "Dolomiti Superski", product: "MS", imageName: "sci_dolomiti-gray.png"},
    {name: "Dovesciare", product: "MS", imageName: "sci_dovesciare.png"},
    {name: "6punto9", product: "MS", imageName: "sport_6punto9.png"},
    {name: "Asd emissionizero", product: "MS", imageName: "sport_asd_emissionizero.png"},
    {name: "EMT Borno", product: "MS", imageName: "sport_emtborno.png"},
    {name: "Neveitalia", product: "MS", imageName: "sport_neveitalia.png"},
    {name: "Cervino", product: "MS", imageName: "Cervino.png"},
    {name: "Courmayeur", product: "MS", imageName: "Courmayeur.jpg"},
    {name: "lathuile", product: "MS", imageName: "sci_lathuile.png"},
    {name: "MonterosaSki", product: "MS", imageName: "MonterosaSki.png"},
    {name: "Pila", product: "MS", imageName: "Pila.png"},
    {name: "Adamello ski", product: "MS", imageName: "sci_adamelloski.png"},
    {name: "Bormio", product: "MS", imageName: "Bormio-Ski-logo.png"},
    {name: "Barzio", product: "MS", imageName: "bobbio-2trasp_orig.png"},
    {name: "3-Zinnen logo", product: "MS", imageName: "3-Zinnen.png"},
    {name: "Alagna logo", product: "MS", imageName: "alagna_6902212.jpg"},
    {name: "AlpeDevero logo", product: "MS", imageName: "AlpeDevero.png"},
    {name: "Aprica logo", product: "MS", imageName: "Aprica.png"},
    {name: "Arabba logo", product: "MS", imageName: "arabba.png"},
    {name: "Bielmonte logo", product: "MS", imageName: "bielmonte2.png"},
    {
        name: "Borno ski",
        product: "MS",
        imageName: "borno_ski_area_vallecamonica_monte_altissimo_estate_inverno_darfo_boario_terme_logo.png"
    },
    {name: "Cimone", product: "MS", imageName: "Cimone.jpg"},
    {name: "Civetta", product: "MS", imageName: "Civetta.jpg"},
    {name: "Corno alle Scale", product: "MS", imageName: "Corno-alle-scale.png"},
    {name: "Domobianca", product: "MS", imageName: "Domobianca_2012-logo-x-sito.gif"},
    {name: "EnjoySki", product: "MS", imageName: "EnjoySki.png"},
    {name: "Estiul SkiSchool", product: "MS", imageName: "Estiul_SkiSchool.png"},
    {name: "Klausberg", product: "MS", imageName: "Klausberg.png"},
    {name: "Macugnaga", product: "MS", imageName: "Macugnaga.jpg"},
    {name: "Mera ski", product: "MS", imageName: "Mera-ski.jpg"},
    {name: "Mondole Ski", product: "MS", imageName: "Mondole-Ski-logo.jpg"},
    {name: "Montepora", product: "MS", imageName: "Monte-pora.png"},
    {name: "Piamprato", product: "MS", imageName: "Piamprato.png"},
    {name: "Pian di Vigezzo", product: "MS", imageName: "Piana-di-vigezzo.png"},
    {name: "Pian neiretto", product: "MS", imageName: "Pian-neiretto.png"},
    {name: "Plan-de-Corones", product: "MS", imageName: "Plan-de-Corones.jpg"},
    {name: "Prali", product: "MS", imageName: "Prali.png"},
    {name: "Presolana Montepora", product: "MS", imageName: "logo_Montepora.png"},
    {name: "Promoturismo", product: "MS", imageName: "promoturismo.png"},
    {name: "PKR Kitesurf", product: "MS", imageName: "sport_pkr_kitesurf.png"},
    {name: "San Domenico di Varzo", product: "MS", imageName: "San-domenico-di-varzo.png"},
    {name: "San Giacomo Roburent", product: "MS", imageName: "SanGiacomoRoburent.png"},
    {name: "Santa Caterina", product: "MS", imageName: "Santa-caterina.jpg"},
    {name: "Sci Sciaremag", product: "MS", imageName: "sci_sciaremag.png"},
    {name: "Scuola Nazionale Sci Sestriere", product: "MS", imageName: "ScuolaNazionaleSciSestriere.png"},
    {name: "SkiEmotion", product: "MS", imageName: "SkiEmotion.png"},
    {name: "Snowit", product: "MS", imageName: "logo_snowit.png"},
    {name: "Snowmet", product: "MS", imageName: "snowmet.jpg"},
    {name: "Speikboden", product: "MS", imageName: "Speikboden.png"},
    {name: "Spiazzi di Gromo", product: "MS", imageName: "SpiazzidiGromo.png"},
    {name: "Stelvio", product: "MS", imageName: "Stelvio.jpg"},
    {name: "Trevalli", product: "MS", imageName: "Trevalli.png"},
    {name: "Tribala", product: "MS", imageName: "logo_tribala.png"},
    {name: "Usseglio", product: "MS", imageName: "Usseglio.png"},
    {name: "Valchiavenna Madesimo", product: "MS", imageName: "ValchiavennaMadesimo.png"},
    {name: "Valgerola", product: "MS", imageName: "Valgerola.jpg"},
    {name: "Valmalenco", product: "MS", imageName: "Valmalenco.png"},


    //SC
    {name: "Dolomiti Superski", product: "SC", imageName: "sci_dolomiti-gray.png"},
    {name: "Cervino", product: "SC", imageName: "Cervino.png"},
    {name: "Courmayeur", product: "SC", imageName: "Courmayeur.jpg"},
    {name: "lathuile", product: "SC", imageName: "sci_lathuile.png"},
    {name: "MonterosaSki", product: "SC", imageName: "MonterosaSki.png"},
    {name: "Pila", product: "SC", imageName: "Pila.png"},
    {name: "Adamello ski", product: "SC", imageName: "sci_adamelloski.png"},
    {name: "Bormio", product: "SC", imageName: "Bormio-Ski-logo.png"},
    {name: "Barzio", product: "SC", imageName: "bobbio-2trasp_orig.png"},
    {name: "3-Zinnen logo", product: "SC", imageName: "3-Zinnen.png"},
    {name: "Alagna logo", product: "SC", imageName: "alagna_6902212.jpg"},
    {name: "AlpeDevero logo", product: "SC", imageName: "AlpeDevero.png"},
    {name: "Aprica logo", product: "SC", imageName: "Aprica.png"},
    {name: "Arabba logo", product: "SC", imageName: "arabba.png"},
    {name: "Bielmonte logo", product: "SC", imageName: "bielmonte2.png"},
    {
        name: "Borno ski",
        product: "SC",
        imageName: "borno_ski_area_vallecamonica_monte_altissimo_estate_inverno_darfo_boario_terme_logo.png"
    },
    {name: "Cimone", product: "SC", imageName: "Cimone.jpg"},
    {name: "Civetta", product: "SC", imageName: "Civetta.jpg"},
    {name: "Corno alle Scale", product: "SC", imageName: "Corno-alle-scale.png"},
    {name: "Domobianca", product: "SC", imageName: "Domobianca_2012-logo-x-sito.gif"},
    {name: "EnjoySki", product: "SC", imageName: "EnjoySki.png"},
    {name: "Estiul SkiSchool", product: "SC", imageName: "Estiul_SkiSchool.png"},
    {name: "Klausberg", product: "SC", imageName: "Klausberg.png"},
    {name: "Macugnaga", product: "SC", imageName: "Macugnaga.jpg"},
    {name: "Mera ski", product: "SC", imageName: "Mera-ski.jpg"},
    {name: "Mondole Ski", product: "SC", imageName: "Mondole-Ski-logo.jpg"},
    {name: "Montepora", product: "SC", imageName: "Monte-pora.png"},
    {name: "Piamprato", product: "SC", imageName: "Piamprato.png"},
    {name: "Pian di Vigezzo", product: "SC", imageName: "Piana-di-vigezzo.png"},
    {name: "Pian neiretto", product: "SC", imageName: "Pian-neiretto.png"},
    {name: "Plan-de-Corones", product: "SC", imageName: "Plan-de-Corones.jpg"},
    {name: "Prali", product: "SC", imageName: "Prali.png"},
    {name: "Presolana Montepora", product: "SC", imageName: "logo_Montepora.png"},
    {name: "Promoturismo", product: "SC", imageName: "promoturismo.png"},
    {name: "San Domenico di Varzo", product: "SC", imageName: "San-domenico-di-varzo.png"},
    {name: "San Giacomo Roburent", product: "SC", imageName: "SanGiacomoRoburent.png"},
    {name: "Santa Caterina", product: "SC", imageName: "Santa-caterina.jpg"},
    {name: "Sci Dovesciare Big", product: "SC", imageName: "sci_dovesciare.png"},
    {name: "Sci Sciaremag", product: "SC", imageName: "sci_sciaremag.png"},
    {name: "Scuola Nazionale Sci Sestriere", product: "SC", imageName: "ScuolaNazionaleSciSestriere.png"},
    {name: "SkiEmotion", product: "SC", imageName: "SkiEmotion.png"},
    {name: "Snowit", product: "SC", imageName: "logo_snowit.png"},
    {name: "Snowmet", product: "SC", imageName: "snowmet.jpg"},
    {name: "Snow week", product: "SC", imageName: "logo_snow_week.png"},
    {name: "Speikboden", product: "SC", imageName: "Speikboden.png"},
    {name: "Spiazzi di Gromo", product: "SC", imageName: "SpiazzidiGromo.png"},
    {name: "Stelvio", product: "SC", imageName: "Stelvio.jpg"},
    {name: "Trevalli", product: "SC", imageName: "Trevalli.png"},
    {name: "Usseglio", product: "SC", imageName: "Usseglio.png"},
    {name: "Valchiavenna Madesimo", product: "SC", imageName: "ValchiavennaMadesimo.png"},
    {name: "Valgerola", product: "SC", imageName: "Valgerola.jpg"},
    {name: "Valmalenco", product: "SC", imageName: "Valmalenco.png"},

];
export const COUNTRIES = [
    {
        "code": "AF",
        "dialCode": "+93",
        "emoji": "🇦🇫",
        "name": "Afghanistan"
    },
    {
        "code": "AL",
        "dialCode": "+355",
        "emoji": "🇦🇱",
        "name": "Albania"
    },
    {
        "code": "DZ",
        "dialCode": "+213",
        "emoji": "🇩🇿",
        "name": "Algeria"
    },
    {
        "code": "AS",
        "dialCode": "+1 684",
        "emoji": "🇦🇸",
        "name": "American Samoa"
    },
    {
        "code": "AD",
        "dialCode": "+376",
        "emoji": "🇦🇩",
        "name": "Andorra"
    },
    {
        "code": "AO",
        "dialCode": "+244",
        "emoji": "🇦🇴",
        "name": "Angola"
    },
    {
        "code": "AI",
        "dialCode": "+1 264",
        "emoji": "🇦🇮",
        "name": "Anguilla"
    },
    {
        "code": "AG",
        "dialCode": "+1268",
        "emoji": "🇦🇬",
        "name": "Antigua and Barbuda"
    },
    {
        "code": "AR",
        "dialCode": "+54",
        "emoji": "🇦🇷",
        "name": "Argentina"
    },
    {
        "code": "AM",
        "dialCode": "+374",
        "emoji": "🇦🇲",
        "name": "Armenia"
    },
    {
        "code": "AW",
        "dialCode": "+297",
        "emoji": "🇦🇼",
        "name": "Aruba"
    },
    {
        "code": "AU",
        "dialCode": "+61",
        "emoji": "🇦🇺",
        "name": "Australia"
    },
    {
        "code": "AT",
        "dialCode": "+43",
        "emoji": "🇦🇹",
        "name": "Austria"
    },
    {
        "code": "AZ",
        "dialCode": "+994",
        "emoji": "🇦🇿",
        "name": "Azerbaijan"
    },
    {
        "code": "BS",
        "dialCode": "+1 242",
        "emoji": "🇧🇸",
        "name": "Bahamas"
    },
    {
        "code": "BH",
        "dialCode": "+973",
        "emoji": "🇧🇭",
        "name": "Bahrain"
    },
    {
        "code": "BD",
        "dialCode": "+880",
        "emoji": "🇧🇩",
        "name": "Bangladesh"
    },
    {
        "code": "BB",
        "dialCode": "+1 246",
        "emoji": "🇧🇧",
        "name": "Barbados"
    },
    {
        "code": "BY",
        "dialCode": "+375",
        "emoji": "🇧🇾",
        "name": "Belarus"
    },
    {
        "code": "BE",
        "dialCode": "+32",
        "emoji": "🇧🇪",
        "name": "Belgium"
    },
    {
        "code": "BZ",
        "dialCode": "+501",
        "emoji": "🇧🇿",
        "name": "Belize"
    },
    {
        "code": "BJ",
        "dialCode": "+229",
        "emoji": "🇧🇯",
        "name": "Benin"
    },
    {
        "code": "BM",
        "dialCode": "+1 441",
        "emoji": "🇧🇲",
        "name": "Bermuda"
    },
    {
        "code": "BT",
        "dialCode": "+975",
        "emoji": "🇧🇹",
        "name": "Bhutan"
    },
    {
        "code": "BO",
        "dialCode": "+591",
        "emoji": "🇧🇴",
        "name": "Bolivia"
    },
    {
        "code": "BA",
        "dialCode": "+387",
        "emoji": "🇧🇦",
        "name": "Bosnia and Herzegovina"
    },
    {
        "code": "BW",
        "dialCode": "+267",
        "emoji": "🇧🇼",
        "name": "Botswana"
    },
    {
        "code": "BR",
        "dialCode": "+55",
        "emoji": "🇧🇷",
        "name": "Brazil"
    },
    {
        "code": "IO",
        "dialCode": "+246",
        "emoji": "🇮🇴",
        "name": "British Indian Ocean Territory"
    },
    {
        "code": "BN",
        "dialCode": "+673",
        "emoji": "🇧🇳",
        "name": "Brunei Darussalam"
    },
    {
        "code": "BG",
        "dialCode": "+359",
        "emoji": "🇧🇬",
        "name": "Bulgaria"
    },
    {
        "code": "BF",
        "dialCode": "+226",
        "emoji": "🇧🇫",
        "name": "Burkina Faso"
    },
    {
        "code": "BI",
        "dialCode": "+257",
        "emoji": "🇧🇮",
        "name": "Burundi"
    },
    {
        "code": "KH",
        "dialCode": "+855",
        "emoji": "🇰🇭",
        "name": "Cambodia"
    },
    {
        "code": "CM",
        "dialCode": "+237",
        "emoji": "🇨🇲",
        "name": "Cameroon"
    },
    {
        "code": "CA",
        "dialCode": "+1",
        "emoji": "🇨🇦",
        "name": "Canada"
    },
    {
        "code": "CV",
        "dialCode": "+238",
        "emoji": "🇨🇻",
        "name": "Cape Verde"
    },
    {
        "code": "KY",
        "dialCode": "+ 345",
        "emoji": "🇰🇾",
        "name": "Cayman Islands"
    },
    {
        "code": "CF",
        "dialCode": "+236",
        "emoji": "🇨🇫",
        "name": "Central African Republic"
    },
    {
        "code": "TD",
        "dialCode": "+235",
        "emoji": "🇹🇩",
        "name": "Chad"
    },
    {
        "code": "CL",
        "dialCode": "+56",
        "emoji": "🇨🇱",
        "name": "Chile"
    },
    {
        "code": "CN",
        "dialCode": "+86",
        "emoji": "🇨🇳",
        "name": "China"
    },
    {
        "code": "CX",
        "dialCode": "+61",
        "emoji": "🇨🇽",
        "name": "Christmas Island"
    },
    {
        "code": "CC",
        "dialCode": "+61",
        "emoji": "🇨🇨",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "code": "CO",
        "dialCode": "+57",
        "emoji": "🇨🇴",
        "name": "Colombia"
    },
    {
        "code": "KM",
        "dialCode": "+269",
        "emoji": "🇰🇲",
        "name": "Comoros"
    },
    {
        "code": "CD",
        "dialCode": "+243",
        "emoji": "🇨🇩",
        "name": "Congo"
    },
    {
        "code": "CG",
        "dialCode": "+242",
        "emoji": "🇨🇬",
        "name": "Congo"
    },
    {
        "code": "CK",
        "dialCode": "+682",
        "emoji": "🇨🇰",
        "name": "Cook Islands"
    },
    {
        "code": "CR",
        "dialCode": "+506",
        "emoji": "🇨🇷",
        "name": "Costa Rica"
    },
    {
        "code": "HR",
        "dialCode": "+385",
        "emoji": "🇭🇷",
        "name": "Croatia"
    },
    {
        "code": "CU",
        "dialCode": "+53",
        "emoji": "🇨🇺",
        "name": "Cuba"
    },
    {
        "code": "CY",
        "dialCode": "+537",
        "emoji": "🇨🇾",
        "name": "Cyprus"
    },
    {
        "code": "CZ",
        "dialCode": "+420",
        "emoji": "🇨🇿",
        "name": "Czech Republic"
    },
    {
        "code": "CI",
        "dialCode": "+225",
        "emoji": "🇨🇮",
        "name": "Côte D'Ivoire"
    },
    {
        "code": "DK",
        "dialCode": "+45",
        "emoji": "🇩🇰",
        "name": "Denmark"
    },
    {
        "code": "DJ",
        "dialCode": "+253",
        "emoji": "🇩🇯",
        "name": "Djibouti"
    },
    {
        "code": "DM",
        "dialCode": "+1 767",
        "emoji": "🇩🇲",
        "name": "Dominica"
    },
    {
        "code": "DO",
        "dialCode": "+1 849",
        "emoji": "🇩🇴",
        "name": "Dominican Republic"
    },
    {
        "code": "EC",
        "dialCode": "+593",
        "emoji": "🇪🇨",
        "name": "Ecuador"
    },
    {
        "code": "EG",
        "dialCode": "+20",
        "emoji": "🇪🇬",
        "name": "Egypt"
    },
    {
        "code": "SV",
        "dialCode": "+503",
        "emoji": "🇸🇻",
        "name": "El Salvador"
    },
    {
        "code": "GQ",
        "dialCode": "+240",
        "emoji": "🇬🇶",
        "name": "Equatorial Guinea"
    },
    {
        "code": "ER",
        "dialCode": "+291",
        "emoji": "🇪🇷",
        "name": "Eritrea"
    },
    {
        "code": "EE",
        "dialCode": "+372",
        "emoji": "🇪🇪",
        "name": "Estonia"
    },
    {
        "code": "ET",
        "dialCode": "+251",
        "emoji": "🇪🇹",
        "name": "Ethiopia"
    },
    {
        "code": "FK",
        "dialCode": "+500",
        "emoji": "🇫🇰",
        "name": "Falkland Islands (Malvinas)"
    },
    {
        "code": "FO",
        "dialCode": "+298",
        "emoji": "🇫🇴",
        "name": "Faroe Islands"
    },
    {
        "code": "FJ",
        "dialCode": "+679",
        "emoji": "🇫🇯",
        "name": "Fiji"
    },
    {
        "code": "FI",
        "dialCode": "+358",
        "emoji": "🇫🇮",
        "name": "Finland"
    },
    {
        "code": "FR",
        "dialCode": "+33",
        "emoji": "🇫🇷",
        "name": "France"
    },
    {
        "code": "GF",
        "dialCode": "+594",
        "emoji": "🇬🇫",
        "name": "French Guiana"
    },
    {
        "code": "PF",
        "dialCode": "+689",
        "emoji": "🇵🇫",
        "name": "French Polynesia"
    },
    {
        "code": "GA",
        "dialCode": "+241",
        "emoji": "🇬🇦",
        "name": "Gabon"
    },
    {
        "code": "GM",
        "dialCode": "+220",
        "emoji": "🇬🇲",
        "name": "Gambia"
    },
    {
        "code": "GE",
        "dialCode": "+995",
        "emoji": "🇬🇪",
        "name": "Georgia"
    },
    {
        "code": "DE",
        "dialCode": "+49",
        "emoji": "🇩🇪",
        "name": "Germany"
    },
    {
        "code": "GH",
        "dialCode": "+233",
        "emoji": "🇬🇭",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "dialCode": "+350",
        "emoji": "🇬🇮",
        "name": "Gibraltar"
    },
    {
        "code": "GR",
        "dialCode": "+30",
        "emoji": "🇬🇷",
        "name": "Greece"
    },
    {
        "code": "GL",
        "dialCode": "+299",
        "emoji": "🇬🇱",
        "name": "Greenland"
    },
    {
        "code": "GD",
        "dialCode": "+1 473",
        "emoji": "🇬🇩",
        "name": "Grenada"
    },
    {
        "code": "GP",
        "dialCode": "+590",
        "emoji": "🇬🇵",
        "name": "Guadeloupe"
    },
    {
        "code": "GU",
        "dialCode": "+1 671",
        "emoji": "🇬🇺",
        "name": "Guam"
    },
    {
        "code": "GT",
        "dialCode": "+502",
        "emoji": "🇬🇹",
        "name": "Guatemala"
    },
    {
        "code": "GG",
        "dialCode": "+44",
        "emoji": "🇬🇬",
        "name": "Guernsey"
    },
    {
        "code": "GN",
        "dialCode": "+224",
        "emoji": "🇬🇳",
        "name": "Guinea"
    },
    {
        "code": "GW",
        "dialCode": "+245",
        "emoji": "🇬🇼",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "dialCode": "+595",
        "emoji": "🇬🇾",
        "name": "Guyana"
    },
    {
        "code": "HT",
        "dialCode": "+509",
        "emoji": "🇭🇹",
        "name": "Haiti"
    },
    {
        "code": "HN",
        "dialCode": "+504",
        "emoji": "🇭🇳",
        "name": "Honduras"
    },
    {
        "code": "HK",
        "dialCode": "+852",
        "emoji": "🇭🇰",
        "name": "Hong Kong"
    },
    {
        "code": "HU",
        "dialCode": "+36",
        "emoji": "🇭🇺",
        "name": "Hungary"
    },
    {
        "code": "IS",
        "dialCode": "+354",
        "emoji": "🇮🇸",
        "name": "Iceland"
    },
    {
        "code": "IN",
        "dialCode": "+91",
        "emoji": "🇮🇳",
        "name": "India"
    },
    {
        "code": "ID",
        "dialCode": "+62",
        "emoji": "🇮🇩",
        "name": "Indonesia"
    },
    {
        "code": "IR",
        "dialCode": "+98",
        "emoji": "🇮🇷",
        "name": "Iran"
    },
    {
        "code": "IQ",
        "dialCode": "+964",
        "emoji": "🇮🇶",
        "name": "Iraq"
    },
    {
        "code": "IE",
        "dialCode": "+353",
        "emoji": "🇮🇪",
        "name": "Ireland"
    },
    {
        "code": "IM",
        "dialCode": "+44",
        "emoji": "🇮🇲",
        "name": "Isle of Man"
    },
    {
        "code": "IL",
        "dialCode": "+972",
        "emoji": "🇮🇱",
        "name": "Israel"
    },
    {
        "code": "IT",
        "dialCode": "+39",
        "emoji": "🇮🇹",
        "name": "Italia"
    },
    {
        "code": "JM",
        "dialCode": "+1 876",
        "emoji": "🇯🇲",
        "name": "Jamaica"
    },
    {
        "code": "JP",
        "dialCode": "+81",
        "emoji": "🇯🇵",
        "name": "Japan"
    },
    {
        "code": "JE",
        "dialCode": "+44",
        "emoji": "🇯🇪",
        "name": "Jersey"
    },
    {
        "code": "JO",
        "dialCode": "+962",
        "emoji": "🇯🇴",
        "name": "Jordan"
    },
    {
        "code": "KZ",
        "dialCode": "+7 7",
        "emoji": "🇰🇿",
        "name": "Kazakhstan"
    },
    {
        "code": "KE",
        "dialCode": "+254",
        "emoji": "🇰🇪",
        "name": "Kenya"
    },
    {
        "code": "KI",
        "dialCode": "+686",
        "emoji": "🇰🇮",
        "name": "Kiribati"
    },
    {
        "code": "XK",
        "dialCode": "+383",
        "emoji": "🇽🇰",
        "name": "Kosovo"
    },
    {
        "code": "KW",
        "dialCode": "+965",
        "emoji": "🇰🇼",
        "name": "Kuwait"
    },
    {
        "code": "KG",
        "dialCode": "+996",
        "emoji": "🇰🇬",
        "name": "Kyrgyzstan"
    },
    {
        "code": "LA",
        "dialCode": "+856",
        "emoji": "🇱🇦",
        "name": "Lao People's Democratic Republic"
    },
    {
        "code": "LV",
        "dialCode": "+371",
        "emoji": "🇱🇻",
        "name": "Latvia"
    },
    {
        "code": "LB",
        "dialCode": "+961",
        "emoji": "🇱🇧",
        "name": "Lebanon"
    },
    {
        "code": "LS",
        "dialCode": "+266",
        "emoji": "🇱🇸",
        "name": "Lesotho"
    },
    {
        "code": "LR",
        "dialCode": "+231",
        "emoji": "🇱🇷",
        "name": "Liberia"
    },
    {
        "code": "LY",
        "dialCode": "+218",
        "emoji": "🇱🇾",
        "name": "Libya"
    },
    {
        "code": "LI",
        "dialCode": "+423",
        "emoji": "🇱🇮",
        "name": "Liechtenstein"
    },
    {
        "code": "LT",
        "dialCode": "+370",
        "emoji": "🇱🇹",
        "name": "Lithuania"
    },
    {
        "code": "LU",
        "dialCode": "+352",
        "emoji": "🇱🇺",
        "name": "Luxembourg"
    },
    {
        "code": "MO",
        "dialCode": "+853",
        "emoji": "🇲🇴",
        "name": "Macao"
    },
    {
        "code": "MK",
        "dialCode": "+389",
        "emoji": "🇲🇰",
        "name": "Macedonia"
    },
    {
        "code": "MG",
        "dialCode": "+261",
        "emoji": "🇲🇬",
        "name": "Madagascar"
    },
    {
        "code": "MW",
        "dialCode": "+265",
        "emoji": "🇲🇼",
        "name": "Malawi"
    },
    {
        "code": "MY",
        "dialCode": "+60",
        "emoji": "🇲🇾",
        "name": "Malaysia"
    },
    {
        "code": "MV",
        "dialCode": "+960",
        "emoji": "🇲🇻",
        "name": "Maldives"
    },
    {
        "code": "ML",
        "dialCode": "+223",
        "emoji": "🇲🇱",
        "name": "Mali"
    },
    {
        "code": "MT",
        "dialCode": "+356",
        "emoji": "🇲🇹",
        "name": "Malta"
    },
    {
        "code": "MH",
        "dialCode": "+692",
        "emoji": "🇲🇭",
        "name": "Marshall Islands"
    },
    {
        "code": "MQ",
        "dialCode": "+596",
        "emoji": "🇲🇶",
        "name": "Martinique"
    },
    {
        "code": "MR",
        "dialCode": "+222",
        "emoji": "🇲🇷",
        "name": "Mauritania"
    },
    {
        "code": "MU",
        "dialCode": "+230",
        "emoji": "🇲🇺",
        "name": "Mauritius"
    },
    {
        "code": "YT",
        "dialCode": "+262",
        "emoji": "🇾🇹",
        "name": "Mayotte"
    },
    {
        "code": "MX",
        "dialCode": "+52",
        "emoji": "🇲🇽",
        "name": "Mexico"
    },
    {
        "code": "FM",
        "dialCode": "+691",
        "emoji": "🇫🇲",
        "name": "Micronesia"
    },
    {
        "code": "MD",
        "dialCode": "+373",
        "emoji": "🇲🇩",
        "name": "Moldova"
    },
    {
        "code": "MC",
        "dialCode": "+377",
        "emoji": "🇲🇨",
        "name": "Monaco"
    },
    {
        "code": "MN",
        "dialCode": "+976",
        "emoji": "🇲🇳",
        "name": "Mongolia"
    },
    {
        "code": "ME",
        "dialCode": "+382",
        "emoji": "🇲🇪",
        "name": "Montenegro"
    },
    {
        "code": "MS",
        "dialCode": "+1664",
        "emoji": "🇲🇸",
        "name": "Montserrat"
    },
    {
        "code": "MA",
        "dialCode": "+212",
        "emoji": "🇲🇦",
        "name": "Morocco"
    },
    {
        "code": "MZ",
        "dialCode": "+258",
        "emoji": "🇲🇿",
        "name": "Mozambique"
    },
    {
        "code": "MM",
        "dialCode": "+95",
        "emoji": "🇲🇲",
        "name": "Myanmar"
    },
    {
        "code": "NA",
        "dialCode": "+264",
        "emoji": "🇳🇦",
        "name": "Namibia"
    },
    {
        "code": "NR",
        "dialCode": "+674",
        "emoji": "🇳🇷",
        "name": "Nauru"
    },
    {
        "code": "NP",
        "dialCode": "+977",
        "emoji": "🇳🇵",
        "name": "Nepal"
    },
    {
        "code": "NL",
        "dialCode": "+31",
        "emoji": "🇳🇱",
        "name": "Netherlands"
    },
    {
        "code": "NC",
        "dialCode": "+687",
        "emoji": "🇳🇨",
        "name": "New Caledonia"
    },
    {
        "code": "NZ",
        "dialCode": "+64",
        "emoji": "🇳🇿",
        "name": "New Zealand"
    },
    {
        "code": "NI",
        "dialCode": "+505",
        "emoji": "🇳🇮",
        "name": "Nicaragua"
    },
    {
        "code": "NE",
        "dialCode": "+227",
        "emoji": "🇳🇪",
        "name": "Niger"
    },
    {
        "code": "NG",
        "dialCode": "+234",
        "emoji": "🇳🇬",
        "name": "Nigeria"
    },
    {
        "code": "NU",
        "dialCode": "+683",
        "emoji": "🇳🇺",
        "name": "Niue"
    },
    {
        "code": "NF",
        "dialCode": "+672",
        "emoji": "🇳🇫",
        "name": "Norfolk Island"
    },
    {
        "code": "KP",
        "dialCode": "+850",
        "emoji": "🇰🇵",
        "name": "North Korea"
    },
    {
        "code": "MP",
        "dialCode": "+1 670",
        "emoji": "🇲🇵",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "NO",
        "dialCode": "+47",
        "emoji": "🇳🇴",
        "name": "Norway"
    },
    {
        "code": "OM",
        "dialCode": "+968",
        "emoji": "🇴🇲",
        "name": "Oman"
    },
    {
        "code": "PK",
        "dialCode": "+92",
        "emoji": "🇵🇰",
        "name": "Pakistan"
    },
    {
        "code": "PW",
        "dialCode": "+680",
        "emoji": "🇵🇼",
        "name": "Palau"
    },
    {
        "code": "PS",
        "dialCode": "+970",
        "emoji": "🇵🇸",
        "name": "Palestinian Territory"
    },
    {
        "code": "PA",
        "dialCode": "+507",
        "emoji": "🇵🇦",
        "name": "Panama"
    },
    {
        "code": "PG",
        "dialCode": "+675",
        "emoji": "🇵🇬",
        "name": "Papua New Guinea"
    },
    {
        "code": "PY",
        "dialCode": "+595",
        "emoji": "🇵🇾",
        "name": "Paraguay"
    },
    {
        "code": "PE",
        "dialCode": "+51",
        "emoji": "🇵🇪",
        "name": "Peru"
    },
    {
        "code": "PH",
        "dialCode": "+63",
        "emoji": "🇵🇭",
        "name": "Philippines"
    },
    {
        "code": "PN",
        "dialCode": "+872",
        "emoji": "🇵🇳",
        "name": "Pitcairn"
    },
    {
        "code": "PL",
        "dialCode": "+48",
        "emoji": "🇵🇱",
        "name": "Poland"
    },
    {
        "code": "PT",
        "dialCode": "+351",
        "emoji": "🇵🇹",
        "name": "Portugal"
    },
    {
        "code": "PR",
        "dialCode": "+1 939",
        "emoji": "🇵🇷",
        "name": "Puerto Rico"
    },
    {
        "code": "QA",
        "dialCode": "+974",
        "emoji": "🇶🇦",
        "name": "Qatar"
    },
    {
        "code": "RO",
        "dialCode": "+40",
        "emoji": "🇷🇴",
        "name": "Romania"
    },
    {
        "code": "RU",
        "dialCode": "+7",
        "emoji": "🇷🇺",
        "name": "Russia"
    },
    {
        "code": "RW",
        "dialCode": "+250",
        "emoji": "🇷🇼",
        "name": "Rwanda"
    },
    {
        "code": "RE",
        "dialCode": "+262",
        "emoji": "🇷🇪",
        "name": "Réunion"
    },
    {
        "code": "BL",
        "dialCode": "+590",
        "emoji": "🇧🇱",
        "name": "Saint Barthélemy"
    },
    {
        "code": "SH",
        "dialCode": "+290",
        "emoji": "🇸🇭",
        "name": "Saint Helena, Ascension and Tristan Da Cunha"
    },
    {
        "code": "KN",
        "dialCode": "+1 869",
        "emoji": "🇰🇳",
        "name": "Saint Kitts and Nevis"
    },
    {
        "code": "LC",
        "dialCode": "+1 758",
        "emoji": "🇱🇨",
        "name": "Saint Lucia"
    },
    {
        "code": "MF",
        "dialCode": "+590",
        "emoji": "🇲🇫",
        "name": "Saint Martin (French Part)"
    },
    {
        "code": "PM",
        "dialCode": "+508",
        "emoji": "🇵🇲",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "code": "VC",
        "dialCode": "+1 784",
        "emoji": "🇻🇨",
        "name": "Saint Vincent and The Grenadines"
    },
    {
        "code": "WS",
        "dialCode": "+685",
        "emoji": "🇼🇸",
        "name": "Samoa"
    },
    {
        "code": "SM",
        "dialCode": "+378",
        "emoji": "🇸🇲",
        "name": "San Marino"
    },
    {
        "code": "ST",
        "dialCode": "+239",
        "emoji": "🇸🇹",
        "name": "Sao Tome and Principe"
    },
    {
        "code": "SA",
        "dialCode": "+966",
        "emoji": "🇸🇦",
        "name": "Saudi Arabia"
    },
    {
        "code": "SN",
        "dialCode": "+221",
        "emoji": "🇸🇳",
        "name": "Senegal"
    },
    {
        "code": "RS",
        "dialCode": "+381",
        "emoji": "🇷🇸",
        "name": "Serbia"
    },
    {
        "code": "SC",
        "dialCode": "+248",
        "emoji": "🇸🇨",
        "name": "Seychelles"
    },
    {
        "code": "SL",
        "dialCode": "+232",
        "emoji": "🇸🇱",
        "name": "Sierra Leone"
    },
    {
        "code": "SG",
        "dialCode": "+65",
        "emoji": "🇸🇬",
        "name": "Singapore"
    },
    {
        "code": "SK",
        "dialCode": "+421",
        "emoji": "🇸🇰",
        "name": "Slovakia"
    },
    {
        "code": "SI",
        "dialCode": "+386",
        "emoji": "🇸🇮",
        "name": "Slovenia"
    },
    {
        "code": "SB",
        "dialCode": "+677",
        "emoji": "🇸🇧",
        "name": "Solomon Islands"
    },
    {
        "code": "SO",
        "dialCode": "+252",
        "emoji": "🇸🇴",
        "name": "Somalia"
    },
    {
        "code": "ZA",
        "dialCode": "+27",
        "emoji": "🇿🇦",
        "name": "South Africa"
    },
    {
        "code": "GS",
        "dialCode": "+500",
        "emoji": "🇬🇸",
        "name": "South Georgia"
    },
    {
        "code": "KR",
        "dialCode": "+82",
        "emoji": "🇰🇷",
        "name": "South Korea"
    },
    {
        "code": "ES",
        "dialCode": "+34",
        "emoji": "🇪🇸",
        "name": "Spain"
    },
    {
        "code": "LK",
        "dialCode": "+94",
        "emoji": "🇱🇰",
        "name": "Sri Lanka"
    },
    {
        "code": "SD",
        "dialCode": "+249",
        "emoji": "🇸🇩",
        "name": "Sudan"
    },
    {
        "code": "SR",
        "dialCode": "+597",
        "emoji": "🇸🇷",
        "name": "Suriname"
    },
    {
        "code": "SJ",
        "dialCode": "+47",
        "emoji": "🇸🇯",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "code": "SZ",
        "dialCode": "+268",
        "emoji": "🇸🇿",
        "name": "Swaziland"
    },
    {
        "code": "SE",
        "dialCode": "+46",
        "emoji": "🇸🇪",
        "name": "Sweden"
    },
    {
        "code": "CH",
        "dialCode": "+41",
        "emoji": "🇨🇭",
        "name": "Switzerland"
    },
    {
        "code": "SY",
        "dialCode": "+963",
        "emoji": "🇸🇾",
        "name": "Syrian Arab Republic"
    },
    {
        "code": "TW",
        "dialCode": "+886",
        "emoji": "🇹🇼",
        "name": "Taiwan"
    },
    {
        "code": "TJ",
        "dialCode": "+992",
        "emoji": "🇹🇯",
        "name": "Tajikistan"
    },
    {
        "code": "TZ",
        "dialCode": "+255",
        "emoji": "🇹🇿",
        "name": "Tanzania"
    },
    {
        "code": "TH",
        "dialCode": "+66",
        "emoji": "🇹🇭",
        "name": "Thailand"
    },
    {
        "code": "TL",
        "dialCode": "+670",
        "emoji": "🇹🇱",
        "name": "Timor-Leste"
    },
    {
        "code": "TG",
        "dialCode": "+228",
        "emoji": "🇹🇬",
        "name": "Togo"
    },
    {
        "code": "TK",
        "dialCode": "+690",
        "emoji": "🇹🇰",
        "name": "Tokelau"
    },
    {
        "code": "TO",
        "dialCode": "+676",
        "emoji": "🇹🇴",
        "name": "Tonga"
    },
    {
        "code": "TT",
        "dialCode": "+1868",
        "emoji": "🇹🇹",
        "name": "Trinidad and Tobago"
    },
    {
        "code": "TN",
        "dialCode": "+216",
        "emoji": "🇹🇳",
        "name": "Tunisia"
    },
    {
        "code": "TR",
        "dialCode": "+90",
        "emoji": "🇹🇷",
        "name": "Turkey"
    },
    {
        "code": "TM",
        "dialCode": "+993",
        "emoji": "🇹🇲",
        "name": "Turkmenistan"
    },
    {
        "code": "TC",
        "dialCode": "+1 649",
        "emoji": "🇹🇨",
        "name": "Turks and Caicos Islands"
    },
    {
        "code": "TV",
        "dialCode": "+688",
        "emoji": "🇹🇻",
        "name": "Tuvalu"
    },
    {
        "code": "UG",
        "dialCode": "+256",
        "emoji": "🇺🇬",
        "name": "Uganda"
    },
    {
        "code": "UA",
        "dialCode": "+380",
        "emoji": "🇺🇦",
        "name": "Ukraine"
    },
    {
        "code": "AE",
        "dialCode": "+971",
        "emoji": "🇦🇪",
        "name": "United Arab Emirates"
    },
    {
        "code": "GB",
        "dialCode": "+44",
        "emoji": "🇬🇧",
        "name": "United Kingdom"
    },
    {
        "code": "US",
        "dialCode": "+1",
        "emoji": "🇺🇸",
        "name": "United States"
    },
    {
        "code": "UY",
        "dialCode": "+598",
        "emoji": "🇺🇾",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "dialCode": "+998",
        "emoji": "🇺🇿",
        "name": "Uzbekistan"
    },
    {
        "code": "VU",
        "dialCode": "+678",
        "emoji": "🇻🇺",
        "name": "Vanuatu"
    },
    {
        "code": "VA",
        "dialCode": "+379",
        "emoji": "🇻🇦",
        "name": "Vatican City"
    },
    {
        "code": "VE",
        "dialCode": "+58",
        "emoji": "🇻🇪",
        "name": "Venezuela"
    },
    {
        "code": "VN",
        "dialCode": "+84",
        "emoji": "🇻🇳",
        "name": "Viet Nam"
    },
    {
        "code": "VG",
        "dialCode": "+1 284",
        "emoji": "🇻🇬",
        "name": "Virgin Islands, British"
    },
    {
        "code": "VI",
        "dialCode": "+1 340",
        "emoji": "🇻🇮",
        "name": "Virgin Islands, U.S."
    },
    {
        "code": "WF",
        "dialCode": "+681",
        "emoji": "🇼🇫",
        "name": "Wallis and Futuna"
    },
    {
        "code": "YE",
        "dialCode": "+967",
        "emoji": "🇾🇪",
        "name": "Yemen"
    },
    {
        "code": "ZM",
        "dialCode": "+260",
        "emoji": "🇿🇲",
        "name": "Zambia"
    },
    {
        "code": "ZW",
        "dialCode": "+263",
        "emoji": "🇿🇼",
        "name": "Zimbabwe"
    }
];
export const NOTY_CONFIG = {
    layout: 'topRight',
    theme: 'nest',
    timeout: 4000,
    progressBar: false,
    visibilityControl: true,
    closeWith: ['click', 'button'],
    animation: {
        open: 'animated fadeInRight',
        close: 'animated fadeOutRight'
    }
};
export const ZENDESK_WIDGET_CONFIG = {
    webWidget: {
        chatLabelOnline: {'*': 'Chat esclusiva clienti gold e rin'},
        chat: {
            suppress: false
        },
        contactForm: {
            suppress: true
        },
        helpCenter: {
            suppress: true
        },
        talk: {
            suppress: true
        },
        answerBot: {
            suppress: true
        }
    }
};
export const MONTHS = [
    {key: 1, value: 'Gennaio'},
    {key: 2, value: 'Febbraio'},
    {key: 3, value: 'Marzo'},
    {key: 4, value: 'Aprile'},
    {key: 5, value: 'Maggio'},
    {key: 6, value: 'Giugno'},
    {key: 7, value: 'Luglio'},
    {key: 8, value: 'Agosto'},
    {key: 9, value: 'Settembre'},
    {key: 10, value: 'Ottobre'},
    {key: 11, value: 'Novembre'},
    {key: 12, value: 'Dicembre'},
]
export const PROVINCES = [
    "AG",
    "AL",
    "AN",
    "AO",
    "AP",
    "AQ",
    "AR",
    "AT",
    "AV",
    "BA",
    "BG",
    "BI",
    "BL",
    "BN",
    "BO",
    "BR",
    "BS",
    "BT",
    "BZ",
    "CA",
    "CB",
    "CE",
    "CH",
    "CL",
    "CN",
    "CO",
    "CR",
    "CS",
    "CT",
    "CZ",
    "EN",
    "EE",
    "FC",
    "FE",
    "FG",
    "FI",
    "FM",
    "FR",
    "GE",
    "GO",
    "GR",
    "IM",
    "IS",
    "KR",
    "LC",
    "LE",
    "LI",
    "LO",
    "LT",
    "LU",
    "MB",
    "MC",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NA",
    "NO",
    "NU",
    "OR",
    "PA",
    "PC",
    "PD",
    "PE",
    "PG",
    "PI",
    "PN",
    "PO",
    "PR",
    "PT",
    "PU",
    "PV",
    "PZ",
    "RA",
    "RC",
    "RE",
    "RG",
    "RI",
    "RM",
    "RN",
    "RO",
    "SA",
    "SI",
    "SO",
    "SP",
    "SR",
    "SS",
    "SU",
    "SV",
    "TA",
    "TE",
    "TN",
    "TO",
    "TP",
    "TR",
    "TS",
    "TV",
    "UD",
    "VA",
    "VB",
    "VC",
    "VE",
    "VI",
    "VR",
    "VT",
    "VV"
]
export const COUNTRY_NAMES = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Côte D'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italia",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan Da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French Part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]
export const REGISTRY_DATA_MATCHING = {
    "professione": {
        "disabled": false,
        "values": [
            {
                "key": "5",
                "value": "Agente di commercio"
            },
            {
                "key": "30",
                "value": "Altro"
            },
            {
                "key": "13",
                "value": "Artigiano"
            },
            {
                "key": "28",
                "value": "Artista"
            },
            {
                "key": "24",
                "value": "Associazione"
            },
            {
                "key": "36",
                "value": "att"
            },
            {
                "key": "27",
                "value": "Barista / Cameriere"
            },
            {
                "key": "12",
                "value": "Casalinga"
            },
            {
                "key": "10",
                "value": "Commerciante"
            },
            {
                "key": "26",
                "value": "Commesso"
            },
            {
                "key": "29",
                "value": "Dipendente pubblico"
            },
            {
                "key": "9",
                "value": "Dirigente / Quadro"
            },
            {
                "key": "17",
                "value": "Disoccupato"
            },
            {
                "key": "14",
                "value": "Ecclesiastico"
            },
            {
                "key": "11",
                "value": "Forze armate"
            },
            {
                "key": "25",
                "value": "Giornalista"
            },
            {
                "key": "7",
                "value": "Impiegato / Funzionario"
            },
            {
                "key": "8",
                "value": "Imprenditore"
            },
            {
                "key": "6",
                "value": "Insegnante"
            },
            {
                "key": "23",
                "value": "Libero professionista"
            },
            {
                "key": "19",
                "value": "Medico"
            },
            {
                "key": "4",
                "value": "Operaio"
            },
            {
                "key": "20",
                "value": "Pensionato"
            },
            {
                "key": "22",
                "value": "Società"
            },
            {
                "key": "3",
                "value": "Sportivo professionista"
            },
            {
                "key": "18",
                "value": "Studente"
            }
        ]
    },
    "provincia": {
        "disabled": false,
        "values": [
            {
                "key": "1",
                "value": "AG"
            },
            {
                "key": "2",
                "value": "AL"
            },
            {
                "key": "3",
                "value": "AN"
            },
            {
                "key": "4",
                "value": "AO"
            },
            {
                "key": "6",
                "value": "AP"
            },
            {
                "key": "104",
                "value": "AQ"
            },
            {
                "key": "5",
                "value": "AR"
            },
            {
                "key": "7",
                "value": "AT"
            },
            {
                "key": "8",
                "value": "AV"
            },
            {
                "key": "9",
                "value": "BA"
            },
            {
                "key": "12",
                "value": "BG"
            },
            {
                "key": "13",
                "value": "BI"
            },
            {
                "key": "10",
                "value": "BL"
            },
            {
                "key": "11",
                "value": "BN"
            },
            {
                "key": "14",
                "value": "BO"
            },
            {
                "key": "17",
                "value": "BR"
            },
            {
                "key": "16",
                "value": "BS"
            },
            {
                "key": "106",
                "value": "BT"
            },
            {
                "key": "15",
                "value": "BZ"
            },
            {
                "key": "18",
                "value": "CA"
            },
            {
                "key": "20",
                "value": "CB"
            },
            {
                "key": "21",
                "value": "CE"
            },
            {
                "key": "24",
                "value": "CH"
            },
            {
                "key": "19",
                "value": "CL"
            },
            {
                "key": "29",
                "value": "CN"
            },
            {
                "key": "25",
                "value": "CO"
            },
            {
                "key": "27",
                "value": "CR"
            },
            {
                "key": "26",
                "value": "CS"
            },
            {
                "key": "22",
                "value": "CT"
            },
            {
                "key": "23",
                "value": "CZ"
            },
            {
                "key": "30",
                "value": "EN"
            },
            {
                "key": "34",
                "value": "FC"
            },
            {
                "key": "31",
                "value": "FE"
            },
            {
                "key": "33",
                "value": "FG"
            },
            {
                "key": "32",
                "value": "FI"
            },
            {
                "key": "108",
                "value": "FM"
            },
            {
                "key": "35",
                "value": "FR"
            },
            {
                "key": "36",
                "value": "GE"
            },
            {
                "key": "37",
                "value": "GO"
            },
            {
                "key": "38",
                "value": "GR"
            },
            {
                "key": "39",
                "value": "IM"
            },
            {
                "key": "40",
                "value": "IS"
            },
            {
                "key": "28",
                "value": "KR"
            },
            {
                "key": "44",
                "value": "LC"
            },
            {
                "key": "43",
                "value": "LE"
            },
            {
                "key": "45",
                "value": "LI"
            },
            {
                "key": "46",
                "value": "LO"
            },
            {
                "key": "42",
                "value": "LT"
            },
            {
                "key": "47",
                "value": "LU"
            },
            {
                "key": "105",
                "value": "MB"
            },
            {
                "key": "48",
                "value": "MC"
            },
            {
                "key": "52",
                "value": "ME"
            },
            {
                "key": "53",
                "value": "MI"
            },
            {
                "key": "49",
                "value": "MN"
            },
            {
                "key": "54",
                "value": "MO"
            },
            {
                "key": "50",
                "value": "MS"
            },
            {
                "key": "51",
                "value": "MT"
            },
            {
                "key": "55",
                "value": "NA"
            },
            {
                "key": "56",
                "value": "NO"
            },
            {
                "key": "57",
                "value": "NU"
            },
            {
                "key": "58",
                "value": "OR"
            },
            {
                "key": "60",
                "value": "PA"
            },
            {
                "key": "66",
                "value": "PC"
            },
            {
                "key": "59",
                "value": "PD"
            },
            {
                "key": "65",
                "value": "PE"
            },
            {
                "key": "63",
                "value": "PG"
            },
            {
                "key": "67",
                "value": "PI"
            },
            {
                "key": "69",
                "value": "PN"
            },
            {
                "key": "71",
                "value": "PO"
            },
            {
                "key": "61",
                "value": "PR"
            },
            {
                "key": "68",
                "value": "PT"
            },
            {
                "key": "64",
                "value": "PU"
            },
            {
                "key": "62",
                "value": "PV"
            },
            {
                "key": "70",
                "value": "PZ"
            },
            {
                "key": "73",
                "value": "RA"
            },
            {
                "key": "74",
                "value": "RC"
            },
            {
                "key": "75",
                "value": "RE"
            },
            {
                "key": "72",
                "value": "RG"
            },
            {
                "key": "76",
                "value": "RI"
            },
            {
                "key": "78",
                "value": "RM"
            },
            {
                "key": "77",
                "value": "RN"
            },
            {
                "key": "79",
                "value": "RO"
            },
            {
                "key": "80",
                "value": "SA"
            },
            {
                "key": "83",
                "value": "SI"
            },
            {
                "key": "85",
                "value": "SO"
            },
            {
                "key": "41",
                "value": "SP"
            },
            {
                "key": "84",
                "value": "SR"
            },
            {
                "key": "81",
                "value": "SS"
            },
            {
                "key": "308",
                "value": "SU"
            },
            {
                "key": "82",
                "value": "SV"
            },
            {
                "key": "86",
                "value": "TA"
            },
            {
                "key": "87",
                "value": "TE"
            },
            {
                "key": "91",
                "value": "TN"
            },
            {
                "key": "89",
                "value": "TO"
            },
            {
                "key": "90",
                "value": "TP"
            },
            {
                "key": "88",
                "value": "TR"
            },
            {
                "key": "93",
                "value": "TS"
            },
            {
                "key": "92",
                "value": "TV"
            },
            {
                "key": "94",
                "value": "UD"
            },
            {
                "key": "95",
                "value": "VA"
            },
            {
                "key": "97",
                "value": "VB"
            },
            {
                "key": "98",
                "value": "VC"
            },
            {
                "key": "96",
                "value": "VE"
            },
            {
                "key": "101",
                "value": "VI"
            },
            {
                "key": "99",
                "value": "VR"
            },
            {
                "key": "102",
                "value": "VT"
            },
            {
                "key": "100",
                "value": "VV"
            },
            {
                "key": "307",
                "value": "XX"
            }
        ]
    },
    "stato": {
        "disabled": false,
        "values": [
            {
                "key": "70",
                "value": "ITALIA"
            }
        ]
    },
    "statoCivile": {
        "disabled": false,
        "values": [
            {
                "key": "0",
                "value": "Nessuno"
            },
            {
                "key": "1",
                "value": "Single"
            },
            {
                "key": "2",
                "value": "Sposato"
            },
            {
                "key": "3",
                "value": "Divorziato"
            },
            {
                "key": "4",
                "value": "Vedovo"
            },
            {
                "key": "5",
                "value": "Separato"
            },
            {
                "key": "6",
                "value": "Convivente"
            }
        ]
    }
}
export const REGISTRY_MODEL = {
    "validationRules": [
        "REGISTRY"
    ],
    "value": {
        "index": 0,
        "roles": [],
        "name": {
            "validationRules": null,
            "value": null
        },
        "surname": {
            "validationRules": null,
            "value": null
        },
        "companyName": {
            "validationRules": null,
            "value": null
        },
        "email": {
            "validationRules": null,
            "value": null
        },
        "phone": {
            "validationRules": null,
            "value": null
        },
        "nationality": {
            "validationRules": null,
            "value": null
        },
        "jobName": {
            "validationRules": null,
            "value": null
        },
        "taxID": {
            "validationRules": null,
            "value": null
        },
        "vatID": {
            "validationRules": null,
            "value": null
        },
        "birthDate": {
            "validationRules": null,
            "value": null
        },
        "address": {
            "streetAddress": {
                "validationRules": null,
                "value": null
            },
            "streetNumber": {
                "validationRules": null,
                "value": null
            },
            "townName": {
                "validationRules": null,
                "value": null
            },
            "zipCode": {
                "validationRules": null,
                "value": null
            },
            "administrativeCode": {
                "validationRules": null,
                "value": null
            },
            "stateCode": {
                "validationRules": null,
                "value": null
            }
        }
    }
}
export const INSUREE_MODEL = {
    snowcare: {
        "validationRules": [
            "INSUREE"
        ],
        "value": {
            "roles": [],
            "name": {
                "validationRules": null,
                "value": null
            },
            "surname": {
                "validationRules": null,
                "value": null
            },
            "birthDate": {
                "validationRules": null,
                "value": null
            },
        }
    }
}

const GENERIC_ERROR_API_400 = "Errore tecnico, qualcosa è andato storto. Riprova più tardi, grazie";
const CONTATTA_SERVIZIO_CLIENTI_MESAGE = "Per qualsiasi chiarimento <a href=\"https://help.24hassistance.com/hc/it/requests/new\" target=\"_blank\">Clicca qui</a> per contattare il Servizio Clienti."
export const API_ERROR_400 = {
    REQUEST_EMPTY_OR_NULL: GENERIC_ERROR_API_400,
    ANIA_DOWN:"Le banche dati esterne da cui recuperiamo i tuoi dettagli assicurativi e del veicolo non sono al momento disponibili. Per questo motivo non riusciamo a fornirti ora un preventivo. Riprova più tardi, grazie.",
    VEHICLE_IS_QUOD: "Il mezzo inserito è un Quad. Ti ricordiamo che la polizza moto e scooter Motoplatinum non assicura i Quad.",
    VEHICLE_IS_RADIATED:"Il veicolo è stato radiato, non è possibile fornirti un preventivo. Riprova con un altro veicolo, grazie",
    POLICY_IS_SUSPENDED:"La tua polizza risulta attualmente sospesa. Potrai calcolare e salvare il preventivo solo  una volta riattivata e quando mancheranno meno di 2 mesi alla scadenza.",
    VEHICLE_NOT_INSURABLE:"La tipologia del veicolo inserito o la destinazione d'uso del veicolo non è assicurabile online. Contatta il Servizio Clienti per ulteriori verifiche. (A007)",
    QUOTATION_CLOSED:"Ci risulta che questo preventivo Motoplatinum non è più valido o già acquistato. Se non hai già acquistato altri preventivi, calcolane uno nuovo.",
    MISSING_QUOTATIONID:GENERIC_ERROR_API_400,
    WRONG_QUOTATION_STATUS:"Ci risulta che questo preventivo Motoplatinum non è più valido o già acquistato o scaduto. Se non hai già acquistato altri preventivi, calcolane uno nuovo.",
    WRONG_QUOTATIONID:GENERIC_ERROR_API_400,
    QUOTATIONID_MISMATCH:GENERIC_ERROR_API_400,
    MISSING_DATA:"Attenzione! Per poter procedere è necessario compilare targa e codice fiscale.",
    SAME_PLATE:`Attenzione! La targa inserita per il recupero della CU (RC familiare) è uguale alla targa del veicolo da assicurare. Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    INVALID_TAXORVATID:`Attenzione! Non troviamo corrispondenza nelle banche dati esterne tra la seconda targa e il codice fiscale inseriti per il recupero della CU (RC familiare). Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    INVALID_ATRC:"Il veicolo di cui si vuole mantenere la CU potrebbe non avere un attestato di rischio valido (A008).<a href=\"https://goo.gl/AHnVr8\" target=\"_blank\">Clicca qui</a> per avere maggiori informazioni.",
    NO_VALID_OR_SUSPENDED_POLICY:`Attenzione! Per la seconda targa e codice fiscale inseriti per il recupero della CU (RC familiare) non esiste una polizza valida o sospesa. Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    PROVINCE_MISMATCH:`Attenzione! Per la seconda targa e codice fiscale inseriti per il recupero della CU (RC familiare) non corrisponde la provincia di residenza. Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    WRONG_OWNER:`Attenzione! La seconda targa indicata per mantenere la CU (RC familiare) ha un proprietario diverso in base al Codice fiscale nelle banche dati (Sivi, SITA). Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    EXPIRED:"Attenzione! la tua sessione è scaduta. Ricarica la pagina per procedere, grazie.",
    NOT_READY:GENERIC_ERROR_API_400,
    INVALID_UNIT_AMOUNT:GENERIC_ERROR_API_400,
    EMAIL_IS_MANDATORY:"Attenzione! inserisci l'indirizzo e-mail a cui inviarti il preventivo Motoplatinum. Senza questo dato non è possibile procedere.",
    FIRSTREGISTRATIONDATE_IS_MANDATORY:"Attenzione! è necessario inserire la data di immatricolazione del mezzo da assicurare per poter calcolare il tuo prevenivo.",
    PURCHASEDATE_IS_MANDATORY:"Attenzione! è necessario inserire la data di acquisto del mezzo da assicurare per poter calcolare il tuo prevenivo.",
    PURCHASEDATE_LESS_THAN_FIRSTREGISTRATIONDATE:`Attenzione! la data di acquisto del mezzo da assicurare è posteriore alla data di immatricolazione. Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    DRIVINGLEVEL_IS_MANDATORY:"DRIVINGLEVEL_IS_MANDATORY",
    INVALID_INSUREDVEHICLEVALUE:`Attenzione! il valore del mezzo assicurato non risulta valido. Verifica i dati inseriti per poter procedere. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    PLATE_IS_MANDATORY:"Attenzione! è necessario inserire la targa del mezzo da assicurare per poter calcolare il tuo prevenivo.",
    INVALID_QUOTATIONEXPIREDATE:"Ops, questo preventivo Motoplatinum risulta scaduto. Calcola uno nuovo preventivo per poter procedere ad assicurare la tua moto o scooter.",
    ALREADY_PAID:`Attenzione, questo preventivo ci risulta già pagato. ${CONTATTA_SERVIZIO_CLIENTI_MESAGE}`,
    MORE_THAN_SIXTY:"La tua polizza scade tra più di 60 giorni, per questo motivo non possiamo calcolarti un preventivo ora. Se sei già nostro cliente riceverai un preventivo di rinnovo un mese prima della scadenza. Se non sei nostro cliente ti invitiamo a riprovare a calcolare un preventivo massimo due mesi prima della scadenza"
}

const getItems = () => JSON.parse(localStorage.getItem('MP_ITEM')) || {};
export const MP_ITEM = getItems()

export const UPADATE_PROPERTY_MP_ITEM = (propertyName, newValue) => {
    MP_ITEM[propertyName] = newValue;
    // Salva l'oggetto aggiornato nel Local Storage
    localStorage.setItem('MP_ITEM', JSON.stringify(MP_ITEM));
}

export const CLEAR_MP_ITEM = () => localStorage.removeItem('MP_ITEM')
